.script(){
    font-family: 'Poiret One';
    font-weight: 400;
}


/*
* Lists
*/

.comma-list {
    li {
        list-style: none;
        white-space: nowrap;
        margin: 0 0 0 .3em;
        padding: 0;
        display: inline-block;

        &:after {
            content: ",";
        }
        &:last-child {
            &:after {
                content: "";
            }
        }
    }
}

.listify(@titleWidth) {
    padding: 0 0 0 @titleWidth;
    margin: 0 .6em .4em;
    list-style: none;

    .comma-list();

    li.title {
        margin-left: -@titleWidth;
        margin-right: 0;
        width: @titleWidth;
        text-align: right;
        font-family: 'Roboto Condensed';
        font-weight: 700;


        &:after {
            content: ":";
        }
    }

    @media(max-width: @screen-xs-min) {
        padding: 0;

        li.title {
            margin-left: 0;
            display: block;
            text-align: left;

            &:after {
                content: "";
            }
        }
    }
}

.inline-list {
    padding: 0;
    margin: 0;
    list-style: none;

    .comma-list();
}



#welcome {
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;

	header {
        // transition: opacity (1000/60)ms linear, top (1000/60)ms linear;

		text-align: center;
		padding: 4em 0 6em;
		white-space: nowrap;
		overflow-x: none;

		h1, h2 {
			padding: 0;
			margin: 0;
			border: none;
		}

		h1 {
			.script();
			margin-bottom: .25em;
			font-size: 20pt;
		}
		h2 {
			font: 700 40pt/100% Roboto Condensed;
			text-transform: uppercase;

			font-size: 20pt; font-size: 7.5vw;
			@media(min-width: @screen-xs-min) { font-size: 36pt; font-size: 7.5vw; }
			@media(min-width: @screen-sm-min) { font-size: 50pt; font-size: 7.5vw; }
			@media(min-width: @screen-md-min) { font-size: 64pt; font-size: 7.5vw; }

		}
	}

}



#overview {
    p {
        font-size: 10pt;

        @media(min-width: @screen-xs-min) { font-size: 11pt; }
        @media(min-width: @screen-sm-min) { font-size: 11pt; }
        @media(min-width: @screen-md-min) { font-size: 13pt; }
    }
}


#photo {
    text-align: center;
    margin: 0 auto;

    max-width: 240px;
    width: 180px;
    width: 50vw;
    padding: 0 0 2em 0;

    @media(min-width: @screen-xs-min) { width: 33vw; float: right; padding: 0 0 2em 2em; }
    @media(min-width: @screen-sm-min) { width: 25vw }
}
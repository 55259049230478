#projects {

    .project  {
        padding-bottom: 4em;

        & + .project {
            padding-top: 4em;
            border-top: 1px solid rgba(0,0,0,0.1);
        }
    }

    .projectDescription {
        padding-left: 1em;

        dl {
            dt {
                font-weight: bold;
                color: rgba(0,0,0,0.7);
            }

            dd {
                padding-left: .75em;
            }

            dd + dt {
                margin-top: 2em;
            }

            &.compact {
                dd + dt {
                    margin-top: 0;
                }
            }

        }
    }

    figure.thumbnail {
        margin: 20px auto 0;
        max-width: 75vw;

        .exampleContainer {
            max-height: 270px;
            overflow-y: auto;
            overflow-x: hidden;
            img {
                width: 100%;
            }
        }

        figcaption p {
            text-align: center;
            font-size: 9pt;
            line-height: 12pt;
            margin: 0;
            padding: 0;
        }
    }

    .tags {
        .listify(8em);
    }

}

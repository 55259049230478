


.contacts {
    .method a {
        display: block;
        text-align: center;
        font-size: 8pt;

        @media(min-width: @screen-xs-min) { font-size: 10pt; }
        @media(min-width: @screen-sm-min) { font-size: 11pt; }
        // @media(min-width: @screen-md-min) { font-size: 13pt; }

        &:hover {
            text-decoration: none;
        }

        .fa {
            font-size: 32pt;
            font-size: 12vw;
            line-height: 32vw;
            line-height: 12vw;
            display: block;

            transition: all 250ms ease-in;
            color: darken(@brand-success, 10%);

            @media(min-width: @screen-lg-min) { font-size: 160px; line-height: 160px }


            &:hover {
                color: @brand-success;
                font-size: 36pt;
                font-size: 14vw;
                @media(min-width: @screen-lg-min) { font-size: 180px; }
            }
        }

    }
}

@tagTitleColumn: 6em;

#knowledge {
    .row + .row, {
        .rowpad();
    }
    @media(max-width: @screen-sm-max) {
        .col-md-6 + .col-md-6 {
            .rowpad();
        }
    }




    // mixins

    .rowpad {
        margin-top: 2em;
    }
}

// based on GitHub
.anchor-target {
    @target-icon-width: 1.2em;

    position: relative;
    a.anchor-link {
        position: absolute;
        display: block;
        left: 0;
        // border: 1px solid red;
        line-height: 1;


        i.fa {
            font-size: 0%;
            font-weight: 400;

            opacity: 0;
            transition: opacity 150ms ease-in, margin 150ms ease-in, font-size 150ms ease-in;

            display: inline-block;
            vertical-align: middle;
            padding: @target-icon-width/2 @target-icon-width/2 @target-icon-width/2 0;

            &:before {
                content: '\f0c1 ';
            }
        }

    }

    &:hover a.anchor-link,
    a.anchor-link:hover {
        i.fa {
            font-size: 50%;
            margin-left: -@target-icon-width;
            opacity: .6;
            &:hover {
                opacity: .8;
            }
        }
    }
}
.tags {
    font-family: Roboto Condensed;
    font-size: 10pt;
    line-height: 1.4em;

    @media(min-width: @screen-xs-min) { font-size: 11pt; }
    @media(min-width: @screen-sm-min) { font-size: 10pt; }
    @media(min-width: @screen-md-min) { font-size: 11pt; }

    .listify(6em);
}

section {
    background-color: #fff;

    font-size: 10pt;
    line-height: 140%;
    @media(min-width: @screen-sm-min) { font-size: 12pt; }
    @media(min-width: @screen-md-min) { font-size: 10pt; }
    @media(min-width: @screen-lg-min) { font-size: 12pt; }

    &.alt {
        background-color: @gray-lighter;
    }

    .section-content {
        font-size: inherit;
        padding: 5em 0;
    }
}
body {
    background-color: @gray-lighter;
    margin-bottom: 100px;
}



h1, h2, h3, h4, h5, h6, dt, label {
    font-family: 'Roboto Condensed';
    font-weight: 300;
}
h1, h2, h3, h4, h5, h6 {
    line-height: 1;
}

h1 {
    font-weight: 300;
    margin: 0 0 .25em 0;
    padding: 0 0 .25em 0;

    font-size: 18pt; font-size: 6vw;
    @media(max-width: @screen-xs-min) { font-size: 16pt; }
    @media(min-width: @screen-md-min) { font-size: 42pt; }
}

h2 {
    padding: 0 0 .25em 0;
    font-size: 16pt; font-size: 4.5vw;
    @media(max-width: @screen-xs-min) { font-size: 14pt; }
    @media(min-width: @screen-md-min) { font-size: 24pt; }

    a {
        .fa.fa-external-link {
            font-size: 50%;
            margin-left: .5em;
        }
    }
}

h3 {
    padding: 0 0 .25em 0;
    font-size: 14pt; font-size: 3vw;
    @media(max-width: @screen-xs-max) { font-size: 12pt; }
    @media(min-width: @screen-md-min) { font-size: 20pt; }
}


p {
    max-width: 48em;
    line-height: 1.4em;
    margin-bottom: 1em;

    &.indent {
        text-indent: 1.5em;
    }
}

code {
    word-wrap:break-word;
}


@import "./navbar.less";
@import "./hamburger.less";
@import "./link-target.less";
@import "./sections.less";
@import "./tag-list.less";
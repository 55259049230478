/*!
*
* external libraries
*
!*/
/*!
*
* config & overrides
*
!*/
/*
* Lists
*/
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
.comma-list li {
  list-style: none;
  white-space: nowrap;
  margin: 0 0 0 .3em;
  padding: 0;
  display: inline-block;
}
.comma-list li:after {
  content: ",";
}
.comma-list li:last-child:after {
  content: "";
}
.inline-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.inline-list li {
  list-style: none;
  white-space: nowrap;
  margin: 0 0 0 .3em;
  padding: 0;
  display: inline-block;
}
.inline-list li:after {
  content: ",";
}
.inline-list li:last-child:after {
  content: "";
}
/*
* Variables
*/
.sandstone {
  font-size: 11px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
}
.navbar .nav > li > a {
  font-size: 11px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
}
.navbar-form input,
.navbar-form .form-control {
  border: none;
}
.btn {
  border: none;
  font-size: 11px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
}
.btn:hover {
  border-color: transparent;
}
.btn-lg {
  line-height: 26px;
}
.btn-default:hover {
  background-color: #393a35;
}
input,
.form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
}
input:focus,
.form-control:focus {
  border-color: #dfd7ca;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.nav {
  font-size: 11px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  border-color: #dfd7ca;
}
.nav-tabs > li > a {
  background-color: #f8f5f0;
  border-color: #dfd7ca;
  color: #98978b;
}
.nav-tabs > li.disabled > a:hover {
  background-color: #f8f5f0;
}
.nav-pills a {
  color: #98978b;
}
.nav-pills li > a {
  border: 1px solid transparent;
}
.nav-pills li.active > a,
.nav-pills li > a:hover {
  border-color: #dfd7ca;
}
.nav-pills li.disabled > a {
  border-color: transparent;
}
.breadcrumb {
  font-size: 11px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid #dfd7ca;
}
.breadcrumb a {
  color: #98978b;
}
.pagination {
  font-size: 11px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
}
.pager {
  font-size: 11px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
}
.pager li > a {
  color: #98978b;
}
.dropdown-menu > li > a {
  font-size: 11px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
}
.alert a,
.alert .alert-link {
  color: #fff;
}
.tooltip {
  font-size: 11px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
}
.progress {
  border-radius: 10px;
  background-color: #dfd7ca;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.list-group-item {
  padding: 16px 24px;
}
.well {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.panel {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.panel .panel-heading,
.panel .panel-title {
  font-size: 11px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
}
.panel .panel-footer {
  font-size: 11px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
}
.panel-default .panel-heading,
.panel-default .panel-title,
.panel-default .panel-footer {
  color: #98978b;
}
/*
@brand-primary:         #325D88;
@brand-success:         #93C54B;
@brand-info:            #29ABE0;
@brand-warning:         #F47C3C;
@brand-danger:          #d9534f;
*/
/*!
*
* app
*
!*/
body {
  background-color: #f8f5f0;
  margin-bottom: 100px;
}
h1,
h2,
h3,
h4,
h5,
h6,
dt,
label {
  font-family: 'Roboto Condensed';
  font-weight: 300;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
}
h1 {
  font-weight: 300;
  margin: 0 0 .25em 0;
  padding: 0 0 .25em 0;
  font-size: 18pt;
  font-size: 6vw;
}
@media (max-width: 480px) {
  h1 {
    font-size: 16pt;
  }
}
@media (min-width: 992px) {
  h1 {
    font-size: 42pt;
  }
}
h2 {
  padding: 0 0 .25em 0;
  font-size: 16pt;
  font-size: 4.5vw;
}
@media (max-width: 480px) {
  h2 {
    font-size: 14pt;
  }
}
@media (min-width: 992px) {
  h2 {
    font-size: 24pt;
  }
}
h2 a .fa.fa-external-link {
  font-size: 50%;
  margin-left: .5em;
}
h3 {
  padding: 0 0 .25em 0;
  font-size: 14pt;
  font-size: 3vw;
}
@media (max-width: 767px) {
  h3 {
    font-size: 12pt;
  }
}
@media (min-width: 992px) {
  h3 {
    font-size: 20pt;
  }
}
p {
  max-width: 48em;
  line-height: 1.4em;
  margin-bottom: 1em;
}
p.indent {
  text-indent: 1.5em;
}
code {
  word-wrap: break-word;
}
.navbar-default {
  margin: 0;
  min-width: 240px;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-collapse,
.navbar-default {
  background-color: #00bce3;
  border: none;
  border-radius: 0;
}
.navbar-default .navbar-toggle {
  border: none;
}
.navbar-default .navbar-toggle:hover {
  background-color: transparent;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: rgba(255, 255, 255, 0.6);
}
.navbar-default .navbar-brand {
  font-family: 'Poiret One';
  font-weight: 400;
  font-size: 20pt;
  line-height: 60px;
  padding: 0 20px;
  margin: 0;
  padding-left: 2vw;
}
.navbar-default .navbar-nav {
  margin: 0;
  padding: 0 20px 0 0;
  padding-right: 2vw;
}
.navbar-default .navbar-nav > li {
  margin: 0;
  padding: 0;
}
.navbar-default .navbar-nav > li > a {
  padding: 0;
  margin: 0;
  color: #fff;
  text-align: center;
  font: 300 12pt/32pt Roboto Condensed;
  width: 100%;
  opacity: .8;
  transition: all 150ms ease-in;
}
@media (min-width: 600px) {
  .navbar-default .navbar-nav > li > a {
    font-size: 1.8vw;
    width: 60px;
    width: 10vw;
    line-height: 60px;
  }
}
@media (min-width: 768px) {
  .navbar-default .navbar-nav > li > a {
    font-size: 1.6vw;
    width: 10vw;
  }
}
@media (min-width: 992px) {
  .navbar-default .navbar-nav > li > a {
    font-size: 1.4vw;
    width: 10vw;
  }
}
@media (min-width: 1600px) {
  .navbar-default .navbar-nav > li > a {
    font-size: 1.2vw;
    width: 9vw;
  }
}
@media (min-width: 1900px) {
  .navbar-default .navbar-nav > li > a {
    font-size: 1vw;
    width: 8vw;
  }
}
@media (min-width: 2300px) {
  .navbar-default .navbar-nav > li > a {
    font-size: .8vw;
    width: 7vw;
  }
}
.navbar-default .navbar-nav > li > a:hover {
  opacity: 1;
  font-size: 14pt;
}
@media (min-width: 600px) {
  .navbar-default .navbar-nav > li > a:hover {
    font-size: 9pt;
  }
}
@media (min-width: 768px) {
  .navbar-default .navbar-nav > li > a:hover {
    font-size: 11pt;
  }
}
@media (min-width: 992px) {
  .navbar-default .navbar-nav > li > a:hover {
    font-size: 1.6vw;
  }
}
@media (min-width: 1600px) {
  .navbar-default .navbar-nav > li > a:hover {
    font-size: 1.3vw;
  }
}
@media (min-width: 1900px) {
  .navbar-default .navbar-nav > li > a:hover {
    font-size: 1.1vw;
  }
}
@media (min-width: 2300px) {
  .navbar-default .navbar-nav > li > a:hover {
    font-size: .9vw;
  }
}
#hamburger {
  text-transform: uppercase;
  font-weight: 300;
  font-family: "Roboto Condensed";
}
#hamburger,
#hamburger > * {
  box-sizing: border-box;
}
#hamburger .toggle {
  position: fixed;
  z-index: 910;
  top: 0;
  right: 0;
  padding: 0;
  opacity: 0;
  height: 60px;
  width: 60px;
  border-bottom-left-radius: 30px;
  font-size: 30px;
  line-height: 60px;
  text-align: center;
  background-color: #00bce3;
}
#hamburger .toggle.enabled {
  opacity: 1;
}
#hamburger .toggle a {
  position: relative;
  top: -0.05em;
  right: -0.05em;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}
#hamburger .toggle a:before {
  content: '\f0c9';
}
#hamburger .toggle.active a:before {
  content: '\f00d';
}
#hamburger .menu {
  transition: all 250ms ease-in;
  background-color: #00bce3;
  white-space: nowrap;
  overflow: hidden;
  position: fixed;
  z-index: 900;
  height: 100%;
  width: 75%;
  right: 0;
  top: 0;
  margin: 0;
  padding: 0;
  font-size: 1em;
  line-height: 1.4em;
  font-size: 8vmin;
  line-height: 12vh;
  border-bottom-left-radius: 30px;
  transform: translate(100%, -100%) scale(0);
}
@media (min-width: 480px) {
  #hamburger .menu {
    font-size: 6vmin;
  }
}
@media (min-width: 768px) {
  #hamburger .menu {
    font-size: 5vmin;
  }
}
#hamburger .menu.enabled {
  border-bottom-left-radius: 25vmin;
  transform: translate(0, 0) scale(1);
}
#hamburger .menu ul {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  overflow: hidden;
}
#hamburger .menu ul li,
#hamburger .menu ul li > a {
  color: #fff;
}
#hamburger .menu ul li:active,
#hamburger .menu ul li > a:active,
#hamburger .menu ul li:hover,
#hamburger .menu ul li > a:hover {
  background-color: transparent;
  text-decoration: none;
}
#hamburger .menu ul li {
  display: block;
  margin: 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}
.anchor-target {
  position: relative;
}
.anchor-target a.anchor-link {
  position: absolute;
  display: block;
  left: 0;
  line-height: 1;
}
.anchor-target a.anchor-link i.fa {
  font-size: 0%;
  font-weight: 400;
  opacity: 0;
  transition: opacity 150ms ease-in, margin 150ms ease-in, font-size 150ms ease-in;
  display: inline-block;
  vertical-align: middle;
  padding: 0.6em 0.6em 0.6em 0;
}
.anchor-target a.anchor-link i.fa:before {
  content: '\f0c1 ';
}
.anchor-target:hover a.anchor-link i.fa,
.anchor-target a.anchor-link:hover i.fa {
  font-size: 50%;
  margin-left: -1.2em;
  opacity: .6;
}
.anchor-target:hover a.anchor-link i.fa:hover,
.anchor-target a.anchor-link:hover i.fa:hover {
  opacity: .8;
}
section {
  background-color: #fff;
  font-size: 10pt;
  line-height: 140%;
}
@media (min-width: 768px) {
  section {
    font-size: 12pt;
  }
}
@media (min-width: 992px) {
  section {
    font-size: 10pt;
  }
}
@media (min-width: 1200px) {
  section {
    font-size: 12pt;
  }
}
section.alt {
  background-color: #f8f5f0;
}
section .section-content {
  font-size: inherit;
  padding: 5em 0;
}
.tags {
  font-family: Roboto Condensed;
  font-size: 10pt;
  line-height: 1.4em;
  padding: 0 0 0 6em;
  margin: 0 .6em .4em;
  list-style: none;
}
@media (min-width: 480px) {
  .tags {
    font-size: 11pt;
  }
}
@media (min-width: 768px) {
  .tags {
    font-size: 10pt;
  }
}
@media (min-width: 992px) {
  .tags {
    font-size: 11pt;
  }
}
.tags li {
  list-style: none;
  white-space: nowrap;
  margin: 0 0 0 .3em;
  padding: 0;
  display: inline-block;
}
.tags li:after {
  content: ",";
}
.tags li:last-child:after {
  content: "";
}
.tags li.title {
  margin-left: -6em;
  margin-right: 0;
  width: 6em;
  text-align: right;
  font-family: 'Roboto Condensed';
  font-weight: 700;
}
.tags li.title:after {
  content: ":";
}
@media (max-width: 480px) {
  .tags {
    padding: 0;
  }
  .tags li.title {
    margin-left: 0;
    display: block;
    text-align: left;
  }
  .tags li.title:after {
    content: "";
  }
}
#welcome {
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
}
#welcome header {
  text-align: center;
  padding: 4em 0 6em;
  white-space: nowrap;
  overflow-x: none;
}
#welcome header h1,
#welcome header h2 {
  padding: 0;
  margin: 0;
  border: none;
}
#welcome header h1 {
  font-family: 'Poiret One';
  font-weight: 400;
  margin-bottom: .25em;
  font-size: 20pt;
}
#welcome header h2 {
  font: 700 40pt/100% Roboto Condensed;
  text-transform: uppercase;
  font-size: 20pt;
  font-size: 7.5vw;
}
@media (min-width: 480px) {
  #welcome header h2 {
    font-size: 36pt;
    font-size: 7.5vw;
  }
}
@media (min-width: 768px) {
  #welcome header h2 {
    font-size: 50pt;
    font-size: 7.5vw;
  }
}
@media (min-width: 992px) {
  #welcome header h2 {
    font-size: 64pt;
    font-size: 7.5vw;
  }
}
#overview p {
  font-size: 10pt;
}
@media (min-width: 480px) {
  #overview p {
    font-size: 11pt;
  }
}
@media (min-width: 768px) {
  #overview p {
    font-size: 11pt;
  }
}
@media (min-width: 992px) {
  #overview p {
    font-size: 13pt;
  }
}
#photo {
  text-align: center;
  margin: 0 auto;
  max-width: 240px;
  width: 180px;
  width: 50vw;
  padding: 0 0 2em 0;
}
@media (min-width: 480px) {
  #photo {
    width: 33vw;
    float: right;
    padding: 0 0 2em 2em;
  }
}
@media (min-width: 768px) {
  #photo {
    width: 25vw;
  }
}
.contacts .method a {
  display: block;
  text-align: center;
  font-size: 8pt;
}
@media (min-width: 480px) {
  .contacts .method a {
    font-size: 10pt;
  }
}
@media (min-width: 768px) {
  .contacts .method a {
    font-size: 11pt;
  }
}
.contacts .method a:hover {
  text-decoration: none;
}
.contacts .method a .fa {
  font-size: 32pt;
  font-size: 12vw;
  line-height: 32vw;
  line-height: 12vw;
  display: block;
  transition: all 250ms ease-in;
  color: #79a736;
}
@media (min-width: 1200px) {
  .contacts .method a .fa {
    font-size: 160px;
    line-height: 160px;
  }
}
.contacts .method a .fa:hover {
  color: #93c54b;
  font-size: 36pt;
  font-size: 14vw;
}
@media (min-width: 1200px) {
  .contacts .method a .fa:hover {
    font-size: 180px;
  }
}
#knowledge .row + .row {
  margin-top: 2em;
}
@media (max-width: 991px) {
  #knowledge .col-md-6 + .col-md-6 {
    margin-top: 2em;
  }
}
#knowledge .rowpad {
  margin-top: 2em;
}
#projects .project {
  padding-bottom: 4em;
}
#projects .project + .project {
  padding-top: 4em;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
#projects .projectDescription {
  padding-left: 1em;
}
#projects .projectDescription dl dt {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
}
#projects .projectDescription dl dd {
  padding-left: .75em;
}
#projects .projectDescription dl dd + dt {
  margin-top: 2em;
}
#projects .projectDescription dl.compact dd + dt {
  margin-top: 0;
}
#projects figure.thumbnail {
  margin: 20px auto 0;
  max-width: 75vw;
}
#projects figure.thumbnail .exampleContainer {
  max-height: 270px;
  overflow-y: auto;
  overflow-x: hidden;
}
#projects figure.thumbnail .exampleContainer img {
  width: 100%;
}
#projects figure.thumbnail figcaption p {
  text-align: center;
  font-size: 9pt;
  line-height: 12pt;
  margin: 0;
  padding: 0;
}
#projects .tags {
  padding: 0 0 0 8em;
  margin: 0 .6em .4em;
  list-style: none;
}
#projects .tags li {
  list-style: none;
  white-space: nowrap;
  margin: 0 0 0 .3em;
  padding: 0;
  display: inline-block;
}
#projects .tags li:after {
  content: ",";
}
#projects .tags li:last-child:after {
  content: "";
}
#projects .tags li.title {
  margin-left: -8em;
  margin-right: 0;
  width: 8em;
  text-align: right;
  font-family: 'Roboto Condensed';
  font-weight: 700;
}
#projects .tags li.title:after {
  content: ":";
}
@media (max-width: 480px) {
  #projects .tags {
    padding: 0;
  }
  #projects .tags li.title {
    margin-left: 0;
    display: block;
    text-align: left;
  }
  #projects .tags li.title:after {
    content: "";
  }
}

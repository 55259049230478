



#hamburger {
    @togglerSize  : 60px;
    @togglerRadius: @togglerSize/2;


    &, & > * {
        box-sizing: border-box;
    }

    text-transform: uppercase;
    font-weight: 300;
    font-family: "Roboto Condensed";


    .toggle {
        position: fixed;
        z-index : 910;

        top    : 0;
        right  : 0;

        padding: 0;
        opacity: 0;

        height : @togglerSize;
        width  : @togglerSize;


        border-bottom-left-radius: @togglerRadius;

        font-size  : @togglerSize/2;
        line-height: @togglerSize;
        text-align : center;

        background-color: @brand-info;



        &.enabled {
            opacity: 1;
        }

        a {
            &:before {
                content: '\f0c9';
            }

            position: relative;
            top     : -.05em;
            right   : -.05em;

            text-decoration: none;
            cursor: pointer;

            color: #fff;
        }

        &.active {
            a:before {
                content: '\f00d';
            }
        }
    }


    .menu {
        transition: all 250ms ease-in;

        background-color: @brand-info;

        white-space: nowrap;
        overflow   : hidden;

        position: fixed;
        z-index : 900;


        height : 100%;
        width  : 75%;

        right  : 0;
        top    : 0;

        margin : 0;
        padding: 0;


        font-size  : 1em;
        line-height: 1.4em;

        font-size  : 8vmin;
        line-height: 12vh;

        @media(min-width: @screen-xs-min) { font-size: 6vmin; }
        @media(min-width: @screen-sm-min) { font-size: 5vmin; }



        // animated properties
        border-bottom-left-radius: @togglerRadius;
        transform: translate(100%, -100%) scale(0);

        &.enabled {
            border-bottom-left-radius: 25vmin;
            transform: translate(0, 0) scale(1);
        }


        ul {
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            white-space: nowrap;
            overflow   : hidden;


            li, li > a {
                color: #fff;
                &:active,
                &:hover {
                    background-color: transparent;
                    text-decoration : none;
                }
            }



            li {
                display: block;
                margin : 0;

                text-align : center;
                white-space: nowrap;
                overflow   : hidden;
            }
        }
    }
}